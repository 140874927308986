import React from "react";
import Fade from "react-reveal/Fade";

class HowItWorks extends React.Component {
    render() {
        return (
            <div>
                <Fade bottom>
                    <div className="m-t-10">
                        <p className="mini-title-offers color-orange">À votre tour</p>
                        <h2 className="special-color title-offers">Qu'est-ce que Pitchersales <br/><span className="color-orange">et en quoi ça consiste</span></h2>
                    </div>
                    <div className="d-flex-desktop content-block-H">
                    </div>
                </Fade>
            </div>
        )
    }
}

export default HowItWorks