import React from 'react';
class legalNotice extends React.Component {
    render() {
        return (
            <div className="block-legal-notice">
                <h2 className="title-notice">Conditions Générales d'Utilisation.</h2>
                <div className="content-legal-notice">

                    <h3 className="mini-title-notice">1. Hébergement</h3>
                    <p className="light-f p-legal">
                        Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).
                    </p>


                    <h3 className="mini-title-notice">2. Préambule</h3>
                    <p className="light-f p-legal">
                        La mission de Pitchersales est est d'accompagner les jeunes porteurs de projets/startups en leur donnant accès au
                        financement nécessaire pour développer leurs projets.
                    </p>
                    <p className="light-f p-legal">
                        Nous organisons régulièrement des événements de pitching en ligne pendant lesquels les porteurs de projets et
                        startuppeurs ont l'opportunité de présenter leur idée plan à un jury d'investisseurs.
                    </p>
                    <p className="light-f p-legal">
                        L'objectif est de permettre aux candidats de bénéficier de l'expertise de professionnels, de visibilité auprès
                        de la communauté startup, mais aussi potentiellement de levées de fonds allant jusqu'à 50 000€.
                    </p>
                    <h3 className="mini-title-notice">3. Définitions</h3>
                    <p className="light-f p-legal">
                        <span className="light-bold">« Pitchersales » </span>désigne la société en charge de la plateforme de pitch en ligne, sa marque et son activité.
                    </p>
                    <p className="light-f">
                        <span className="light-bold">« Conditions d’Utilisation »</span> désignent les présentes conditions générales d’utilisation.
                    </p>
                    <p className="light-f p-legal">
                        <span className="light-bold">« Site »</span> désigne le site Internet de Pitchersales accessible à l’adresse suivante : https://pitchersales.com/
                    </p>
                    <p className="light-f p-legal">
                        <span className="light-bold">« Porteur de projet »</span> membre candidat qui souhaite présenter son idée entrepreneuriale lors d'un événement de pitch en ligne Pitchersales.
                    </p>
                    <p className="light-f p-legal">
                        <span className="light-bold">« Investisseur »</span> membre participant au jury d'évaluation des projets présentés et pouvant financer certains d'entre eux.
                    </p>

                    <p className="light-f p-legal">
                        <span className="light-bold">« Startups »</span> désignent les sociétés en recherche de Financement Participatif en Capital présentés sur le Site.
                    </p>

                    <p className="light-f p-legal">
                        <span className="light-bold">« Conseil »</span> service optionnel proposé aux porteurs de projets pour les aider dans leurs démarches de préparation au pitch et de recherche de financement.
                    </p>

                    <p className="light-f p-legal">
                        <span className="light-bold">« Client »</span> tout professionnel ou personne physique capable au sens des articles 1123 et suivants du Code civil, ou personne morale, qui visite le Site objet des présentes conditions générales.
                    </p>

                    <p className="light-f p-legal">
                        <span className="light-bold">« Informations clients »</span>  Ci après dénommé « Information (s) » qui correspondent à l’ensemble des données personnelles susceptibles d’être détenues par https://pitchersales.com/home pour la gestion de votre compte, de la gestion de la relation client et à des fins d’analyses et de statistiques.
                    </p>

                    <h3 className="mini-title-notice">4. Acceptation des Conditions d’Utilisation</h3>
                    <p className="light-f p-legal">
                        En naviguant sur le Site, l’Internaute accepte les Conditions d’Utilisation sans réserve. A défaut d’acceptation des Conditions d’Utilisation, l’Internaute comprend qu’il ne doit pas utiliser le Site de quelque façon que ce soit.

                        Ces Conditions d’Utilisation devront être considérées comme celles applicables au moment de leur acceptation qu’elle soit tacite ou expresse.

                        Les Conditions d’Utilisation s’appliquent sans limitation de durée, pendant toute la durée de navigation sur le Site, et s’agissant d’un Membre, pendant la durée d’utilisation de l’espace personnel et jusqu’à ce que de nouvelles conditions remplacent les présentes ou résiliation.
                    </p>
                    <p className="light-f p-legal">
                        Pitchersales agit en tant qu'intermédiaire, fournissant aux porteurs de projets un espace pour exposer leurs idées et leurs besoins de financement, tout en mettant à la disposition des investisseurs les informations nécessaires pour prendre des décisions d'investissement éclairées. Nous visons à simplifier le processus de levée de fonds, permettant aux porteurs de projets de lever jusqu'à 50 000 € en moins de 50 jours.
                    </p>
                    <p className="light-f p-legal">
                        Notre engagement est de favoriser l'innovation, la croissance et l'opportunité en créant une plateforme où les rêves des entrepreneurs peuvent devenir réalité, tout en offrant aux investisseurs une diversification de leurs portefeuilles. Pitchersales s'efforce de maintenir un environnement transparent, équitable et dynamique, propice au succès tant des porteurs de projets que des investisseurs.
                    </p>

                    <h3 className="mini-title-notice">5. Description des Services</h3>
                    <p className="light-f p-legal">
                        Pitchersales met à disposition des porteurs de projets et startups plusieurs services clés pour optimiser leur recherche de financement:
                    </p>
                    <ul className="light-f p-legal" >
                        <li> Accès à notre plateforme de pitching en ligne sécurisée. Déposez votre dossier de candidature et présentez-vous simplement devant les investisseurs connectés.</li>
                        <li> Organisation régulière d'événements de pitching virtuels. Chaque édition permet à plusieurs startups de bénéficier d'un coup de projecteur auprès d'experts financiers.</li>
                        <li> Mise en relation directe avec notre réseau d'investisseurs partenaires. Des business angels et fonds spécialisés dans les belles idées de demain.</li>
                        <li> Conseils personnalisés et formations au pitching. Nos coachs accompagnent les candidats dans leurs présentations pour maximiser leur attractivité.</li>
                        <li>Veille sur les appels à projets et levées de fonds. Nous tenons nos membres informés des meilleures opportunités de financement.</li>
                        <li>Mention dans notre catalogue en ligne. Un gage de visibilité supplémentaire pour convaincre de nouveaux partenaires.</li>
                    </ul>

                    <h3 className="mini-title-notice">6. Les risques</h3>

                    <h3 className="mini-title-notice color-black m-l-5">6.1 Absence de couverture des risques pour les investisseurs</h3>
                    <p className="light-f p-legal">
                        L'investissement dans un projet de financement participatif comporte des risques, y compris le risque de perte partielle ou totale du capital investi.

                        Votre investissement n'est pas couvert par les systèmes de garantie des dépôts établis conformément à la directive 2014/49/UE du Parlement européen et du Conseil.

                        Votre investissement n'est pas non plus couvert par les systèmes d'indemnisation des investisseurs établis conformément à la directive 97/9/CE du Parlement européen et du Conseil (point 22).

                        Il se peut que vous ne receviez aucun rendement sur votre investissement.

                        Il ne s'agit pas d'un produit d'épargne, et nous vous conseillons de ne pas investir plus de 10 % de votre patrimoine net dans des projets de financement participatif.

                        Il se peut que vous ne soyez pas en mesure de vendre les instruments d'investissement quand vous le souhaitez. Si vous êtes en mesure de les vendre, vous pouvez néanmoins subir des pertes.
                    </p>

                    <h3 className="mini-title-notice color-black m-l-5">6.2 Absence de couverture des risques pour les entrepreneurs</h3>
                    <ul className="light-f p-legal">
                        <li>
                            Risque de non sélection pour le pitching: La qualité et la maturité du dossier de candidature conditionnent sa sélection pour présenter le projet. Il n'y a pas de garantie d'accès à l'événement.
                        </li>
                        <li>
                            Risque d'échec de la présentation: Malgré les conseils prodigués, le porteur de projet peut ne pas convaincre le jury lors de son pitch. La qualité de l'oral et du discours détermine les chances de succès.
                        </li>
                        <li>
                            Risque d'incompatibilité avec les attentes des investisseurs: Le projet présenté peut ne pas correspondre aux secteurs, stades ou types d'engagements recherchés par les financeurs.
                        </li>
                        <li>
                            Risque concurrentiel: D'autres candidats peuvent présenter des projets plus aboutis ou innovants. L'obtention de fonds n'est pas garantie même en cas de bon déroulé du pitch.
                        </li>
                        <li>
                            Risque d'évolution du projet: Le business planinitialement présenté peut se révéler non conforme aux résultats effectifs pendant le développement de la startup.
                        </li>
                        <li>
                            Risque d'aléas techniques: Tout dysfonctionnement de la plateforme ou incident pendant le pitch pouvant impacter négativement la prestation.
                        </li>
                    </ul>

                    <h3 className="mini-title-notice">7. Gestion des conflits d'intérêts</h3>
                    <p className="light-f p-legal">
                        Conformément au règlement (UE) 2020/1503 du Parlement européen et du Conseil, Pitchersales a établi une procédure de gestion des conflits d'intérêts. Pitchersales n'a aucune participation dans une quelconque offre de financement participatif sur sa plateforme.

                        Pitchersales n'accepte comme porteurs de projets en lien avec les services de financement participatif sur sa plateforme de financement participatif aucune des personnes suivantes :

                        a) ses associés détenteurs de 20 % ou plus du capital social ou des droits de vote ;

                        b) ses dirigeants ou salariés ;

                        c) toute personne physique ou morale liée à ces associés, dirigeants ou salariés par une relation de contrôle au sens de l'article 4, paragraphe 1, point 35) b), de la directive 2014/65/UE du Parlement européen et du Conseil.

                        Des procédures internes ont été mises en place par Pitchersales pour s'assurer du respect de ces règles.

                        Pour toute question à ce sujet, vous pouvez nous contacter via l'adresse contact@pitchersales.com.

                        Ces Conditions Générales d'Utilisation ont été mises à jour le 7 novembre 2023.
                    </p>

                    <h3 className="mini-title-notice">8. Gestion des données personnelles.</h3>
                    <h3 className="mini-title-notice color-black m-l-5">8.1 Responsables de la collecte des données personnelles</h3>
                    <p className="light-f p-legal">
                        Pour les Données Personnelles collectées dans le cadre de la création du compte personnel de l’Utilisateur et de sa navigation sur le Site, le responsable du traitement des Données Personnelles est : Pitchersales. https://pitchersales.com/
                        est représenté par Manon Derydt, son représentant légal
                        En tant que responsable du traitement des données qu’il collecte, https://pitchersales.com/ s’engage à respecter le cadre des
                        dispositions légales en vigueur. Il lui appartient notamment au Client d’établir les finalités de ses traitements de données,
                        de fournir à ses prospects et clients, à partir de la collecte de leurs consentements, une information complète sur le traitement
                        de leurs données personnelles et de maintenir un registre des traitements conforme à la réalité. Chaque fois que https://pitchersales.com/
                        traite des Données Personnelles, https://pitchersales.com/ prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de
                        la pertinence des Données Personnelles au regard des finalités pour lesquelles https://pitchersales.com/ les traite.
                    </p>

                    <h3 className="mini-title-notice color-black m-l-5">8.2 Finalité des données collectées</h3>
                    <p className="light-f p-legal">
                        https://pitchersales.com/ est susceptible de traiter tout ou partie des données :
                    </p>
                    <ul className="light-f p-legal">
                        <li>pour permettre la navigation sur le Site et la gestion et la traçabilité des prestations et services commandés par l’utilisateur : données de connexion et d’utilisation du Site, facturation, historique des commandes, etc.</li>
                        <li>pour prévenir et lutter contre la fraude informatique (spamming, hacking…) : matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé)</li>
                        <li>pour améliorer la navigation sur le Site : données de connexion et d’utilisation</li>
                        <li>droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)</li>
                        <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)</li>
                        <li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui https://pitchersales.com/home devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
                    </ul>

                    <p className="light-f p-legal">
                        Dès que https://pitchersales.com/home a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, https://pitchersales.com/ s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.
                    </p>

                    <p className="light-f p-legal">
                        Si l’Utilisateur souhaite savoir comment https://pitchersales.com/home utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter https://pitchersales.com/home par écrit à l’adresse suivante :
                    </p>

                    <p className="light-f p-legal">
                        Pitchersales – DPO, Manon Derydt
                        42 rue Véron 75018 Paris.
                        Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que https://pitchersales.com/ corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport).
                    </p>

                    <p className="light-f p-legal">
                        Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à https://pitchersales.com/home par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de https://pitchersales.com/home peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (https://www.cnil.fr/fr/plaintes).
                    </p>

                    <h3 className="mini-title-notice color-black m-l-5">8.3 Droit d’accès, de rectification et d’opposition</h3>

                    <p className="light-f p-legal">
                        Conformément à la réglementation européenne en vigueur, les Utilisateurs de https://pitchersales.com/ disposent des droits suivants :
                    </p>

                    <ul className="light-f p-legal">
                        <li>droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite</li>
                        <li>droit de retirer à tout moment un consentement (article 13-2c RGPD)</li>
                        <li>droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD)</li>
                        <li>droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)</li>
                        <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)</li>
                        <li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui https://pitchersales.com/home devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
                    </ul>

                    <p className="light-f p-legal">
                        Dès que https://pitchersales.com/ a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, https://pitchersales.com/home s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.
                    </p>

                    <p className="light-f p-legal">
                        Si l’Utilisateur souhaite savoir comment https://pitchersales.com/ utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter https://pitchersales.com/home par écrit à l’adresse suivante :
                    </p>

                    <p className="light-f p-legal">
                        Pitchersales – DPO, Manon Derydt<br/>
                        42 rue Véron 75018 Paris.
                    </p>

                    <p className="light-f p-legal">
                        Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que https://pitchersales.com/ corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport).
                    </p>

                    <p className="light-f p-legal">
                        Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à https://pitchersales.com/ par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de https://pitchersales.com/ peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (https://www.cnil.fr/fr/plaintes).
                    </p>

                    <h3 className="mini-title-notice color-black m-l-5">8.4 Non-communication des données personnelles</h3>

                    <p className="light-f p-legal">
                        Pitchersales s’interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, https://pitchersales.com/home reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’il présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).
                    </p>

                    <p className="light-f p-legal">
                        Pitchersales s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de Pitchersales, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections prises. Par ailleurs Pitchersales ne collecte aucune « données sensibles ».
                    </p>

                    <p className="light-f p-legal">
                        Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de Pitchersales et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la présente politique.
                    </p>

                    <p className="light-f p-legal">
                        Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs de Pitchersales sont principalement les agents de notre service client.
                    </p>

                    <h3 className="mini-title-notice">9. Engagement et Responsabilité de Pitchersales</h3>
                    <p className="light-f p-legal">
                        Chez Pitchersales, nous nous engageons à fournir une plateforme fiable et équitable pour les porteurs de projets et les investisseurs. Nous faisons tout notre possible pour assurer la qualité de nos services et la sécurité des transactions effectuées sur notre plateforme. Cependant, il est important de noter que Pitchersales agit en tant qu'intermédiaire et n'assume aucune responsabilité directe quant à la réussite ou à l'échec des projets présentés sur notre plateforme.

                        Nous encourageons les porteurs de projets à présenter des propositions précises et à gérer de manière responsable les fonds collectés. De même, nous exhortons les investisseurs à effectuer des évaluations approfondies avant de prendre des décisions d'investissement. Pitchersales ne peut garantir le succès financier de tout projet ni l'exactitude des informations fournies.

                        Nous nous engageons à maintenir un environnement transparent et à coopérer avec nos utilisateurs pour résoudre les problèmes qui pourraient survenir. Cependant, chaque partie impliquée dans une transaction est responsable de ses actions, et Pitchersales ne peut être tenu responsable des conséquences qui en découlent.

                        Nous mettons tout en œuvre pour promouvoir des relations saines entre les porteurs de projets et les investisseurs, en encourageant la communication et la confiance mutuelle. Notre objectif est de créer une plateforme où les opportunités se concrétisent et où les rêves deviennent réalité, tout en rappelant à tous nos utilisateurs l'importance de l'engagement et de la responsabilité dans leurs actions.
                    </p>
                    <h3 className="mini-title-notice">10. Accéder aux événements organisés par Pitchersales</h3>
                    <p className="light-f p-legal">
                        Pitchersales créera un outil permettant l’accès de ses Membres à des évènements organisés par Pitchersales.
                        Pitchersales ne garantit pas un volume d’événements et les Membres comprennent qu’ils peuvent n’avoir accès à aucun événement si aucun événement n’est créé par Pitchersales.
                    </p>
                    <h3 className="mini-title-notice">11. Notification d’incident</h3>
                    <p className="light-f p-legal">
                        Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de
                        stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue.
                        Si nous prenions connaissance d'une brèche de la sécurité, nous avertirions les utilisateurs concernés afin
                        qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte
                        de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous nous engageons à informer
                        pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes
                        les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.
                    </p>

                    <p className="light-f p-legal">
                        Aucune information personnelle de l'utilisateur du site https://pitchersales.com/ n'est publiée à l'insu de l'utilisateur,
                        échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de
                        Pitchersales et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur
                        qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du
                        site https://pitchersales.com/.
                    </p>

                    <h3 className="mini-title-notice">12. Propriété intellectuelle</h3>
                    <p className="light-f p-legal">Pitchersales est propriétaire du Site, tant dans ses composantes techniques que graphiques, textuelles ou autres, à l’exclusion des contenus fournis par par les Startups, les investisseurs et les conseillers.</p>
                    <p className="light-f p-legal">Pitchersales est donc seule titulaire de l’ensemble des droits de propriété intellectuelle afférents au Site, à son contenu ainsi qu’aux bases de données assurant son fonctionnement, et l’utilisation du Site ne donne aux Membres aucun droit sur l’un quelconque de ces éléments, sous réserve des droits afférents aux contenus fournis par les M
                        membres.
                    </p>
                    <p className="light-f p-legal">L’acceptation des présentes Conditions d’Utilisation vaut reconnaissance par les startups et investisseirs des droits de propriété intellectuelle de Pitchersales ci-dessus énumérés et l’engagement de les respecter.</p>
                    <h3 className="mini-title-notice">13. Droit applicable et attribution de juridiction</h3>
                    <p className="light-f p-legal">
                        Tout litige en relation avec l’utilisation du site https://pitchersales.com/ est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris
                    </p>
                </div>
                <p className="m-l-15"><i>Dernière mise à jour le 07/11/2023</i></p>
            </div>
        )
    }
}
export default legalNotice