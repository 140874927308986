import React from 'react';
import Fade from "react-reveal/Fade";
class HeadbandI extends React.Component {
    render() {
        return (
            <div className="content-headband">

                <Fade top>
                    <h2 className="text-container"> Il est temps de diversifier <span className="violet-color">vos investissements.</span></h2>
                </Fade>
            </div>
        )
    }
}

export default HeadbandI